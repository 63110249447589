import React from 'react';
import NextButton from "./NextButton"
import AnimatedText from './AnimatedText';
import {getThemeColor} from '../utils/colors';
import Helmet from 'react-helmet'

const GameIntro = ({text, color, bg, onFinished, button, buttonColor, buttonTextColor }) => {

  const textArray = text.split(" ");

  const textParts = [];
  for(var i = 0; i < textArray.length; i++){
    const text = textArray[i];
    textParts.push(<React.Fragment key={i}>{text}&nbsp;</React.Fragment>);
    if(text.indexOf(",") > -1
      || text.indexOf(".") > -1
      || text.indexOf(":") > -1
      || text.indexOf(";") > -1
      || text.indexOf("!") > -1
      || text.indexOf("?") > -1){
      //add a extra element to create a pause for periods and commas
      textParts.push(<React.Fragment key={i + "."}></React.Fragment>);
    }
  }

  const bgColor = getThemeColor(bg, "blue");
  const textColor = getThemeColor(color, "gold");

  return <>
    <Helmet>
      <meta name="theme-color" content={bgColor} />
    </Helmet>
    <div className={`h-full w-full`} style={{background:bgColor, color:textColor}}>
      <div className={`widget-area flex items-center justify-center`}>
        <div className={`text-5xl p-6 text-helvetica-neue game-intro`}>
          <AnimatedText>
            {textParts}
          </AnimatedText>
        </div>
      </div>
      <div className="button-area">
        <NextButton text={button || "Next"} onClick={onFinished} bg={buttonColor} color={buttonTextColor} />
      </div>
    </div>
  </>
}

export default GameIntro;
