import React, { useState, useEffect } from "react"

import ActivityFlow from "./ActivityFlow";

const ActivityContainer = ({ games, interuptions, structure }) => {

  const [flow, setFlow] = useState([]);

  useEffect(() => {

    const iterations = 0

    const flow = []
    flow.push(getActivityById(structure, "structure", "intro"));
    flow.push(getActivityById(structure, "structure", "entryForm"));
    flow.push(getActivityById(structure, "structure", "entryFormStageTwo"));
    flow.push(getActivityById(interuptions, "interuptions", "static"));
    flow.push(getActivityById(games, "games", "madlibs1"));
    flow.push(getActivityById(interuptions, "interuptions", "static"));
    flow.push(getActivityById(games, "games", "celeb1"));
    flow.push(getActivityById(interuptions, "interuptions", "sheep"));
    flow.push(getActivityById(games, "games", "feud1"));
    flow.push(getActivityById(interuptions, "interuptions", "skier"));
    flow.push(getActivityById(games, "games", "wouldYou1"));
    flow.push(getActivityById(interuptions, "interuptions", "staysunny"));
    flow.push(getActivityById(games, "games", "pictureThis1"));
    flow.push(getActivityById(interuptions, "interuptions", "uncleFrogger"));
    flow.push(getActivityById(games, "games", "riddles"));
    flow.push(getActivityById(structure, "structure", "end"));

    for (var i = 0; i < iterations; i++) {
      const game = getUnseenActivity(games, "games");
      flow.push(game);
      const interuption = getUnseenActivity(interuptions, "interuptions");
      flow.push(interuption);
    }

    setFlow(flow);

  }, []);

  let initalIndex = 0;
  if (typeof window != 'undefined') {
    initalIndex = getParameterByName("index", window.location.href);
  }
  return <>
    {flow && flow.length ?
      <ActivityFlow flow={flow} initalIndex={initalIndex} />
      :
      <div className="w-full h-full bg-gold"></div>
    }
  </>

}

const getActivityById = (activities, key, id) => {
  const storageKey = `sspp-seen-${key}`;
  try {
    const seenString = typeof window != 'undefined' ? window.localStorage.getItem(storageKey) : "[]";
    const seen = JSON.parse(seenString) || [];

    const activity = activities.filter((activity) => {
      return activity.id === id
    })[0];

    seen.push(activity);

    if (typeof window != 'undefined') window.localStorage.setItem(storageKey, JSON.stringify(seen));
    return activity;
  } catch (e) {
    console.log(e);
  }
}

const getUnseenActivity = (activities, key) => {
  const storageKey = `sspp-seen-${key}`;
  try {
    const seenString = typeof window != 'undefined' ? window.localStorage.getItem(storageKey) : "[]";
    const seen = JSON.parse(seenString);

    const unseen = activities.filter((activity) => {
      if (seen) {
        return seen.indexOf(activity.id) === -1
      } else {
        return true;
      }
    });

    if (unseen.length) {
      const activity = get_random(unseen);
      if (typeof window != 'undefined') window.localStorage.setItem(storageKey, JSON.stringify(seen));
      return activity;
    } else {
      const activity = get_random(activities);
      if (typeof window != 'undefined') window.localStorage.setItem(storageKey, JSON.stringify([activity.id]));
      return activity;
    }
  } catch (e) {
    console.log(e);
    const activity = get_random(activities);
    if (typeof window != 'undefined') window.localStorage.setItem(storageKey, JSON.stringify([activity.id]));
    return activity;
  }
}

function get_random(list) {
  return list[Math.floor((Math.random() * list.length))];
}

function getParameterByName(name, url) {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}


export default ActivityContainer;
