import React from "react";
import NextButton from "./NextButton"
import AnimatedText from './AnimatedText';
import CircleZoomBg from "./CircleZoomBg";
import { motion } from "framer-motion"
import {getThemeColor} from '../utils/colors';
import Helmet from 'react-helmet'

const MadlibsForm = ({results, onFinished}) => {

  const bgColor = getThemeColor("gold");

  return <>
    <Helmet>
        <meta name="theme-color" content={bgColor} />
    </Helmet>
    <div className={`h-full w-full bg-red text-brown`}>
    <CircleZoomBg />
    <div className={`widget-area flex items-center justify-center`}>
      <div className={`text-3xl madlibs-result text-helvetica-neue p-6 leading-normal`}>
        <AnimatedText delay={.5}>
          {results}
        </AnimatedText>
      </div>
    </div>
    <div className="button-area">
      <NextButton text="next" onClick={onFinished} delay={1} />
    </div>
  </div>
  </>
}

export default MadlibsForm;
