import { withPrefix } from "gatsby"


export const getJson = async (src) => {
  //console.log(src);
  window.lottieData = window.lottieData || {};
  if(window.lottieData[src]){
    return window.lottieData[src];
  }else{
    const response = await fetch(withPrefix(src));
    const json = await response.json();
    window.lottieData[src] = json;
    return json;
  }
}
// 
//
// export const get = async (request) => {
//   if ('caches' in window){
//     if(!window.vsvCache);
//       const vsvCache = await caches.open('vsv-cache');
//       window.vsvCache = vsvCache;
//     }
//
//     window.vsvCache.
//
//   }
// }
