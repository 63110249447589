import React from 'react';
import { motion } from "framer-motion"

const AnimatedText = ({children, delay = 0}) => {

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.25,
        delay: delay
      }
    }
  }

  const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 }
  }

  return <motion.div
            variants={container}
            initial="hidden"
            animate="show"
          >
            {children.map((child,index) => (
              <motion.span key={index} variants={item} style={{display:"inline-block"}}>
                {child}
              </motion.span>
            ))}
          </motion.div>

}

export default AnimatedText
