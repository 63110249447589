import React from "react";
import MadlibsInput from "./MadlibsInput"
import { motion } from "framer-motion"

const MadlibsAnimatedInputs = ({ matches, entries = [], active, exiting, onClick }) => {

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    },
    exit: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  }

  const item = {
    hidden: { opacity: 0, x: 100 },
    show: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -100 }
  }

  const inputs = matches.map((match, index) => {
    return <motion.div className="madlibs-input-outer" key={index} variants={item} transition={{ duration: .75, ease: 'easeOut' }}>
      <MadlibsInput val={entries[index]} type={match[1]} active={active == index} onClick={() => onClick(index)} />
    </motion.div>
  });

  return <motion.div
    className="h-full flex flex-col justify-evenly"
    variants={container}
    initial="hidden"
    animate={exiting ? "exit" : "show"}
  >
    {inputs}
  </motion.div>

}

export default MadlibsAnimatedInputs;
