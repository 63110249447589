import React from "react";

const MadlibsInput = ({ val, type, active, onClick }) => {

  let suffix;
  if (type.indexOf("ing") > -1) {
    suffix = "ing"
  }
  let label;
  if (type.indexOf("verb") > -1) {
    label = "Verb"
  }
  if (type.indexOf("noun") > -1) {
    label = "Noun"
  }
  if (type.indexOf("adjective") > -1) {
    label = "Adj"
  }
  if (type.indexOf("adverb") > -1) {
    label = "Adv"
  }

  return <div className={`flex rounded-full items-center mb-2 overflow-hidden whitespace-nowrap madlibs-input ${active ? "bg-white" : "bg-gold"}`} onClick={onClick}>
    <div className="bg-brown text-gold text-right h-full flex items-center justify-end px-2 text-helvetica-neue" style={{ width: "30%" }}>{label}:</div>
    <div className={`px-2 ${active ? "text-brown" : "text-white"}`}>
      <span className={`${active ? "cursor" : ""}`}>{val}</span>
      {suffix && <span className="text-red">{suffix}</span>}
    </div>
  </div>
}

export default MadlibsInput;
