import React, { useRef, useState, useEffect } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import NextButton from "./NextButton";
import Helmet from 'react-helmet'
import {getThemeColor} from '../utils/colors';
import {getJson} from "../utils/cache";

const Lottie = ({id, src, autoplay = true, loop = false, hideButton = false, nextButton = false, controls = false, onFinished, buttonColor, buttonTextColor, buttonText, bg, startBg, endBg}) => {

    const [lottieData, setLottieData] = useState(null);
    const [bgColor, setBgColor] = useState(startBg || bg);

    useEffect(()=>{
      getData();
    }, [src])

    const getData = async () => {
      const data = await getJson(src);
      setLottieData(data);
    }

    const bgColorRgb = getThemeColor(bgColor);

    return (<>
      <Helmet>
        <meta name="theme-color" content={bgColorRgb} />
      </Helmet>
      <div className="relative h-full w-full" style={{"background":bgColorRgb}}>
        <div className="lottie-aspect">
        {lottieData &&
          <Player
            className="absolute"
            onEvent={event => {
              if(event == "play"){
                setBgColor(bg)
              }
              if(event == "complete" && !nextButton){
                 endBg && setBgColor(endBg)
                 onFinished && onFinished()
              }
            }}
            autoplay={autoplay}
            keepLastFrame={true}
            loop={loop}
            controls={true}
            src={lottieData}
            style={{ height: '100%', width: '100%' }}
          ></Player>}
        </div>
        {(!hideButton && (loop || nextButton)) ?
          <div className="button-area absolute bottom-0">
            <NextButton onClick={onFinished} text={ buttonText || "Let's Go"} color={buttonTextColor} bg={buttonColor}/>
          </div>
          :null}
      </div>
    </>
    );
}

export default Lottie;
