import React, {useState} from "react";
import Lottie from "./Lottie";
import NextButton from "./NextButton";
import Helmet from "react-helmet";
import { getThemeColor } from '../utils/colors';

const WouldYouRatherOption = ({options, bgSrc, onFinished, transitionSrc}) => {

  const [intro, setIntro] = useState(true);

  const handleLottieFinished = () => {
    if(intro){
      setIntro(false);
    }else{
      onFinished();
    }
  }

  const bgColor0 = getThemeColor(options[0].bg, "red");
  const bgColor1 = getThemeColor(options[1].bg, "blue");
  const bgStartColor = getThemeColor("gold");

  return <div className={`h-full w-full bg-blue relative text-3xl text-center`}>
          <Helmet>
            <meta name="theme-color" content={bgColor0} />
          </Helmet>
          <div className="bg-red h-1/2 w-full absolute top-0 text-white p-6 flex justify-center items-center" style={{background: bgColor0}}>{options[0].value}</div>
          <div className="bg-red h-1/2 w-full absolute bottom-0 text-white p-6 flex justify-center items-center" style={{background: bgColor1}}>{options[1].value}<br/><br/><br/></div>
          <Lottie src={intro ? transitionSrc : bgSrc} loop={!intro} onFinished={handleLottieFinished} buttonColor="brown" buttonText="Next" startBg={bgStartColor} />
          <div className="w-full absolute text-white p-6 flex justify-center font-bold items-center" style={{height: "20%", top: "40%"}}>OR</div>
  </div>
}

export default WouldYouRatherOption;
