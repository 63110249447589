import React, {useState} from 'react';
import GameIntro from "./GameIntro";
import CelebrityDinner from "./CelebrityDinner";
import CelebrityDinnerResults from "./CelebrityDinnerResults";
import { motion } from "framer-motion"

import "../styles/celeb.css"

const CelebrityDinnerContainer = ({introText, introBg, introColor, celebrities, onFinished}) => {

  const [results, setResults] = useState([])
  const [gamePhase, setGamePhase] = useState(0)

  const handleSelection = (selected) => {
    setResults(selected);
    setGamePhase(gamePhase + 1);
  }

  return <>
    {[
      <GameIntro text={introText} bg={introBg} color={introColor} onFinished={()=>setGamePhase(1)}/>,
      <CelebrityDinner celebrities={celebrities} onNext={handleSelection}/>,
      <CelebrityDinnerResults results={results} onNext={onFinished}/>,
    ][gamePhase]}
  </>
}

export default CelebrityDinnerContainer;
