import React, { useState } from 'react';
import GameIntro from "./GameIntro";
import Lottie from "./Lottie";
import ActivityFlow from "./ActivityFlow";
import FeudGame from "./FeudGame";

const FuedContainer = ({ introText, introBg, introColor, intoButton, intoButtonColor, questionFlow, onFinished, surveySays }) => {

  const [gamePhase, setGamePhase] = useState(0)


  const handleLoss = () => {
    try {
      typeof window !== "undefined" && window.gtag("event", "fued_finished", {
        win: false,
        activity: "fued"
      });
    } catch (e) {
      console.log(e);
    }
    setGamePhase(5)
  }

  const handleWin = () => {
    try {
      typeof window !== "undefined" && window.gtag("event", "fued_guess", {
        win: true,
        activity: "fued"
      });
    } catch (e) {
      console.log(e);
    }
    setGamePhase(4)
  }

  return <>
    {[
      <Lottie src="/042722_Feud_Intro_V01.json" onFinished={() => setGamePhase(1)} bg="red" />,
      <GameIntro text={introText} bg={introBg} color={introColor} button={intoButton} buttonColor={intoButtonColor} onFinished={() => setGamePhase(2)} />,
      <ActivityFlow flow={questionFlow} onFinished={() => setGamePhase(3)} />,
      <FeudGame surveySays={surveySays} onLoss={handleLoss} onWin={handleWin} />,
      <Lottie src="/042722_LotsofPoints_V01.json" onFinished={onFinished} bg="blue" />,
      <Lottie src="/042722_NoBueno_Test_V01.json" onFinished={onFinished} bg="blue" />,
    ][gamePhase]}
  </>
}

export default FuedContainer;
