import * as React from "react"
import '../styles/global.css'
import ActivityContainer from "../components/ActivityContainer"
import games from "../json/Games.json";
import interuptions from "../json/Interuptions.json";
import structure from "../json/Structure.json";

const IndexPage = () => {

  return (
    <main className="text-base bg-gold flex justify-center items-center">
      <div className="iphone-outer">
        <div className="iphone-inner">
          <div className="activity-wrapper">
            <ActivityContainer games={games} interuptions={interuptions} structure={structure}/>
          </div>
        </div>
      </div>
    </main>
  )
}

export default IndexPage
