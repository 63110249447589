import React, {useState} from "react";
import Lottie from "./Lottie";
import NextButton from "./NextButton";
import { motion } from 'framer-motion';
import Helmet from 'react-helmet'
import { getThemeColor } from '../utils/colors';
import { withPrefix } from 'gatsby'

const PictureThisImage = ({src, bgSrc, words, onFinished, introSrc}) => {

  const [intro, setIntro] = useState(true);

  const handleLottieFinished = () => {
    if(intro){
      setIntro(false);
    }else{
      onFinished();
    }
  }

  const wordsContainer = {
    out: {},
    in: {
      transition: {
        staggerChildren: .25,
        delayChildren: .5
      }
    }
  }
  const wordsItem = {
    out:{
      y:"200%",
      opacity: 0
    },
    in:{
      y:"0%",
      opacity: 1
    }
  }

  const bgColor = getThemeColor("brown");

  return <>
    <Helmet>
      <meta name="theme-color" content={bgColor} />
    </Helmet>
    <div className={`h-full w-full bg-blue relative text-3xl text-center`}>
          <div className="bg-brown h-full w-full absolute top-0 text-white flex justify-center items-center">
            <motion.img initial={{opacity:0}} animate={{opacity: 1}} transition={{duration: .5, ease: "easeIn"}} className="w-full" src={withPrefix(src)} />
          </div>
          <Lottie src={intro ? introSrc : bgSrc} loop={!intro} onFinished={handleLottieFinished} buttonColor="brown" buttonText="Next"/>
          <motion.div variants={wordsContainer} initial="out" animate="in" className="h-1/3 w-full absolute bottom-0 text-gold p-6 flex flex-col justify-center items-center" style={{bottom: "15%"}}>
            {words.map((word, i) =>
              <motion.div key={i} variants={wordsItem} className="p-1" transition={{ease:"easeIn", duration:.5}}>
                {word}
              </motion.div>
            )}
          </motion.div>
      </div>
  </>
}

export default PictureThisImage;
