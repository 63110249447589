import React, { useState } from 'react';
import GameIntro from "./GameIntro";
import PictureThisImage from "./PictureThisImage";

import "../styles/madlibs.css";


const PictureThisContainer = ({ introText, introBg, introColor, introButton, introButtonColor, introButtonTextColor, imageIntroSrc, imageLoopSrc, images, onFinished }) => {

  const [gamePhase, setGamePhase] = useState(0);

  if (typeof window != 'undefined') {
    const numberOfPlayers = window.localStorage.getItem("sspp-entryFormStageTwo");
    console.log(numberOfPlayers);
    if (numberOfPlayers == "One") {
      onFinished && onFinished();
    }
  }

  const handleNextImage = () => {
    if (gamePhase < images.length) {
      setGamePhase(gamePhase + 1);
    } else {
      onFinished && onFinished();
    }
  }

  const imageNodes = images.map((image) => <PictureThisImage src={image.src} introSrc={imageIntroSrc} bgSrc={imageLoopSrc} words={image.words} onFinished={handleNextImage} />)

  return <>
    {[
      <GameIntro text={introText} bg={introBg} color={introColor} button={introButton} buttonColor={introButtonColor} buttonTextColor={introButtonTextColor} onFinished={() => setGamePhase(1)} />,
      ...imageNodes
    ][gamePhase]}
  </>
}

export default PictureThisContainer;
