import React, { useState } from 'react';
import { motion } from "framer-motion"
import NextButton from './NextButton';
import CircleZoomBg from "./CircleZoomBg";
import Helmet from 'react-helmet'
import { getThemeColor } from '../utils/colors';
import "../styles/final-form.css";
import { withPrefix } from "gatsby";
import { navigate } from 'gatsby';

const ChooseOne = () => {

  const [option, setOption] = useState("");
  const bgColor = getThemeColor("blue");

  const container = {
    initial: {},
    animate: {
      transition: {
        staggerChildren: .5,
        delay: .5,
      }
    }
  }

  const items = {
    initial: {
      y: "100vh"
    },
    animate: {
      y: "0%"
    }
  }

  const itemTransition = {
    ease: "easeOut",
    duration: 1
  }

  const handleAction = (action) => () => {

    try {
      typeof window !== "undefined" && window.gtag("event", "exit", {
        location: action,
      });
    } catch (e) {
      console.log(e);
    }

    switch (action) {
      case "replay":
        navigate("/?index=0");
        break;
      case "space":
        navigate("/space");
        break;
      case "trophy":
        navigate("/trophy");
        break;
      case "staySunny":
        window.location = "https://visitsunvalley.com/staysunny";
        break;
      case "blog":
        window.location = "https://www.visitsunvalley.com/searching-for-sun-valley/";
        break
    }
  }

  return <div className="bg-gold text-white p-2 h-full">
    <Helmet>
      <meta name="theme-color" content={bgColor} />
    </Helmet>
    <motion.div
      animate={{ y: 0 }}
      initial={{ y: "200%", scale: 1.3 }}
      transition={{ duration: 1, ease: "easeOut" }}
      className="bg-blue h-full w-full rounded-full absolute"
    ></motion.div>
    <motion.div
      animate="animate"
      initial="initial"
      variants={container}
      className="flex flex-col h-full text-red text-2xl justify-center p-4"
    >
      <motion.button
        variants={items}
        className="final-form-button border-red rounded-full border-2 p-4"
        transition={itemTransition}
        onClick={handleAction("replay")}>
        Replay the game
      </motion.button>
      <motion.button
        variants={items}
        className="final-form-button border-red rounded-full border-2 p-4"
        transition={itemTransition}
        onClick={handleAction("staySunny")}>
        Explore Stay Sunny
      </motion.button>
      <motion.button
        variants={items}
        className="final-form-button border-red rounded-full border-2 p-4"
        transition={itemTransition}
        onClick={handleAction("blog")}>
        Explore Sun Valley ID
      </motion.button>
      <motion.button
        variants={items}
        className="final-form-button border-red rounded-full border-2 p-4"
        transition={itemTransition}
        onClick={handleAction("trophy")}>
        Win a trophy
      </motion.button>
      <motion.button
        variants={items}
        className="final-form-button border-red rounded-full border-2 p-4"
        transition={itemTransition}
        onClick={handleAction("space")}>
        Enter Space
      </motion.button>
    </motion.div>
  </div>
}

export default ChooseOne;
