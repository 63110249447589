import React, { useRef } from "react"
import Keyboard from "react-simple-keyboard"
import 'react-simple-keyboard/build/css/index.css';
import "../styles/form.css";

const StaySunnyKeyboard = React.forwardRef(({onChange, onKeyPress}, ref) => {

  const layout = {
    'default': [
      'q w e r t y u i o p',
      'a s d f g h j k l',
      '{enter} z x c v b n m {bksp}',
      '{space}'
    ]
  }

  const keyboard = useRef();

  return <Keyboard
            onChange={onChange}
            onKeyPress={onKeyPress}
            layout={layout}
            keyboardRef={r => {
              if(ref) ref.current = r;
            }}
            display={{
              '{bksp}': '⌫',
              '{enter}': 'ENTER',
              '{space}': 'SPACE'
            }}
          />

});

export default StaySunnyKeyboard;
