import React, { useState, useEffect } from "react";
import CelebrityTile from "./CelebrityTile";
import { motion } from "framer-motion"

const CelebrityDinner = ({ celebrities, onNext }) => {

  const [selected, setSelected] = useState([]);
  const [x, setX] = useState(0)

  useEffect(() => {
    if (selected.length > 2) {
      const results = celebrities.filter((celeb) => (selected.indexOf(celeb.id) > -1));
      window.setTimeout(() => setX(100), 300);
      window.setTimeout(() => onNext(results), 2000);
    }
  }, [selected])

  const handleSelection = (id) => {

    try {
      typeof window !== "undefined" && window.gtag("event", "celeb_selected", {
        id: id,
        activity: "celebrities"
      });
    } catch (e) {
      console.log(e);
    }

    const newSelected = [...selected];
    const index = selected.indexOf(id);
    if (index == -1) {
      newSelected.push(id);
    } else {
      newSelected.splice(index, 1);
    }
    setSelected(newSelected);
  }

  const row1 = celebrities.slice(0, 3).map((celebrity) => <CelebrityTile key={1} celebrity={celebrity} selected={selected.indexOf(celebrity.id) > -1} onSelected={handleSelection} />)
  const row2 = celebrities.slice(3, 6).map((celebrity) => <CelebrityTile key={2} celebrity={celebrity} selected={selected.indexOf(celebrity.id) > -1} onSelected={handleSelection} />)
  const row3 = celebrities.slice(6, 9).map((celebrity) => <CelebrityTile key={3} celebrity={celebrity} selected={selected.indexOf(celebrity.id) > -1} onSelected={handleSelection} />)

  return <div className="flex flex-col justify-items-stretch h-full bg-gold">
    <motion.div
      initial={{ x: "-100%" }}
      animate={{ x: `${x}%` }}
      transition={{ ease: "easeOut", duration: .9 }}
      className="flex justify-items-stretch h-full w-full">
      {row1}
    </motion.div>
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: `${x * -1}%` }}
      transition={{ ease: "easeOut", duration: .9 }}
      className="flex justify-items-stretch h-full w-full">
      {row2}
    </motion.div>
    <motion.div
      initial={{ x: "-100%" }}
      animate={{ x: `${x}%` }}
      transition={{ ease: "easeOut", duration: .9 }}
      className="flex justify-items-stretch h-full w-full">
      {row3}
    </motion.div>
  </div>
}

export default CelebrityDinner;
