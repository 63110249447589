import React, {useState} from 'react';
import GameIntro from "./GameIntro";
import Lottie from "./Lottie";
import WouldYouRatherOption from "./WouldYouRatherOption";

import { motion } from "framer-motion"

import "../styles/madlibs.css";


const WouldYouRatherContainer = ({introText, introBg, introColor, introButton, introButtonColor, introButtonTextColor, introSrc, titleSrc, titleTransitionSrc, optionsBg, options, onFinished}) => {

  const [gamePhase, setGamePhase] = useState(0)

  const handleNextOption = () => {
    if(gamePhase < options.length + 2){
      setGamePhase(gamePhase + 1);
    }else{
      onFinished && onFinished();
    }
  }

  const optionNodes = options.map((options)=><WouldYouRatherOption bgSrc={optionsBg} options={options} onFinished={handleNextOption} transitionSrc={titleTransitionSrc} />);

  return <>
    {[
      <Lottie src={introSrc} onFinished={()=>setGamePhase(1)} bg="gold" endBg="brown" />,
      <GameIntro text={introText} bg={introBg} color={introColor} button={introButton} buttonColor={introButtonColor} buttonTextColor={introButtonTextColor} onFinished={()=>setGamePhase(2)}/>,
      <Lottie src={titleSrc} onFinished={()=>setGamePhase(3)} startBg="brown" bg="yellow"/>,
      ...optionNodes
    ][gamePhase]}
  </>
}

export default WouldYouRatherContainer;
