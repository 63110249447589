import React, { useState, useEffect, useCallback, useRef } from 'react';
import Keyboard from './Keyboard';
import FeudInput from "./FeudInput";
import Helmet from 'react-helmet';
import NextButton from "./NextButton";
import '../styles/feud.css'
import { motion } from "framer-motion"
import { withPrefix } from "gatsby"
import { getThemeColor } from '../utils/colors';

const FeudGame = ({ surveySays, onWin, onLoss }) => {
  const [value, setValue] = useState("");
  const [results, setResults] = useState(surveySays.map(() => false));
  const [strikes, setStrikes] = useState(0);
  const [showStrikes, setShowStrikes] = useState(false);
  const [enableStrikes, setEnableStrikes] = useState(false);
  const [lost, setLost] = useState(false);

  useEffect(() => {
    if (results.indexOf(false) == -1) {
      onWin();
    }
  }, [results]);

  useEffect(() => {
    setShowStrikes(true);
    setEnableStrikes(true);
    window.setTimeout(() => {
      setShowStrikes(false);
    }, 2000)
    window.setTimeout(() => {
      if (strikes > 2) {
        setLost(true);
      }
      setEnableStrikes(false);
    }, 2500)
  }, [strikes]);

  const keyboard = useRef();

  const guess = () => {
    let match = false;
    let matchedValue = "";
    surveySays.map((answer, index) => {
      if (answer.valid.map(v => v.toLowerCase()).indexOf(value.toLowerCase()) > -1) {
        matchedValue = answer.answer;
        const newResults = [...results];
        newResults[index] = true;
        setResults(newResults);
        match = true;
      };
    });
    if (!match) {
      setStrikes(strikes + 1);
    }

    try {
      typeof window !== "undefined" && window.gtag("event", "fued_guess", {
        value: value,
        matched: match,
        matchedValue: matchedValue,
        activity: "fued"
      });
    } catch (e) {
      console.log(e);
    }

    keyboard.current.clearInput();
    setValue("");
  }

  const onKeyPress = (key) => {
    if (key == "{enter}") guess();
  }
  const strikeNodes = [];
  for (var i = 0; i < strikes; i++) {
    strikeNodes.push(<img key={i} className={`${strikes == 1 ? "w-3/5" : (strikes == 2 ? "w-2/5" : "w-1/3")}`} src={withPrefix("/vsv_pp_familyfued_crossout.svg")} />);
  }

  const answerContainer = {
    hidden: {},
    show: {
      transition: {
        staggerChildren: .05,
        ease: "easeOut",
        duration: 1
      }
    }
  }

  const answerItem = {
    hidden: { y: "100vh" },
    show: { y: "0px" }
  }

  const bgColor = getThemeColor("blue")

  return <>
    <Helmet>
      <meta name="theme-color" content={bgColor} />
    </Helmet>
    {enableStrikes ?
      <div className={`absolute strikes ${showStrikes ? "strikes--shown" : ""} flex w-full top-1/4 z-99 justify-center`}>
        {strikeNodes}
      </div>
      : null}
    <div className="flex flex-col bg-blue h-full justify-evenly">
      <motion.div variants={answerContainer} initial="hidden" animate="show" className="feud-answers px-4 flex flex-col justify-evenly">
        {surveySays.map((answer, index) => (
          <motion.div variants={answerItem} key={index} transition={{ duration: 1 }} className={`${results[index] ? "feud-answer--shown" : ""} ${lost && !results[index] ? "feud-answer--wrong" : ""} feud-answer overflow-hidden m-1 my-3 rounded-lg bg-brown border-white border relative font-bebas text-3xl`}>
            <div className="flex flex-col justify-around items-center feud-answer__text relative">
              <span className="rounded-full bg-gold color-brown feud-answer__number text-center text-2xl border-white border flex justify-center items-center">{index + 1}</span>
              <span className="text-gold">{answer.answer}</span>
            </div>
          </motion.div>
        ))}
      </motion.div>

      <motion.div initial={{ y: "150%", opacity: 1 }} animate={{ y: 0, opacity: lost ? 0 : 1 }} transition={{ duration: 1, ease: "easeOut", delay: .5 }} className="feud-input px-2 keyboard-text-white">
        <FeudInput value={value} onOK={guess} />
        <Keyboard
          onChange={setValue}
          onKeyPress={onKeyPress}
          ref={keyboard}
        />
      </motion.div>
      {lost ?
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, ease: "easeOut", delay: 1 }} className="button-area absolute bottom-0">
          <NextButton onClick={onLoss} text="Keep Going" color="blue" bg="gold" />
        </motion.div>
        : null}
    </div>
  </>
}

export default FeudGame;
