import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion"
import NextButton from './NextButton';
import CircleZoomBg from "./CircleZoomBg";
import Helmet from 'react-helmet'
import { getThemeColor } from '../utils/colors';

const ChooseOne = ({ id, labelText, options, onFinished }) => {

  const [option, setOption] = useState("");
  const bgColor = getThemeColor("gold");

  useEffect(() => {
    if (option) {
      typeof window != 'undefined' && window.localStorage.setItem("sspp-" + id, option);
      try {
        typeof window !== "undefined" && window.gtag("event", "choose_one", {
          key: id,
          value: option
        });
      } catch (e) {
        console.log(e);
      }
    }
  }, [option])

  return <div className="bg-red text-white p-2 h-full flex flex-col justify-stretch">
    <Helmet>
      <meta name="theme-color" content={bgColor} />
    </Helmet>
    <CircleZoomBg />
    <motion.div initial={{ scale: .9 }} animate={{ scale: 1 }} transition={{ duration: 1, ease: "easeOut" }} className="widget-area relative flex flex-col justify-evenly">
      <div className="text-blue p-4 text-4xl entry-form-label text-helvetica-neue relative">
        <label>{labelText}</label>
      </div>
      <div>
        {options.map((op, i) => <div key={i} onClick={() => setOption(op)} style={{ border: "6px solid" }} className={`rounded-full text-6xl m-4 p-4 text-center font-bold ${option == op ? "bg-white text-gold" : " text-red"}`}>
          {op}
        </div>)}
      </div>
    </motion.div>
    <div className="button-area relative">
      {option && <NextButton text="next" onClick={onFinished} />}
    </div>
  </div>
}

export default ChooseOne;
