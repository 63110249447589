import React from 'react';

const FeudInput = ({value, onOK}) => {
  return <>
    <div className="bg-white rounded-full relative h-12 mb-3 text-brown">
      <span className="h-full absolute w-full top-0 left-0 flex text-center items-center justify-center text-xl cursor">
        {value}
      </span>
      <button onClick={onOK} className="rounded-full absolute bg-red right-0 top-0 text-white h-12 w-12 flex justify-center items-center">OK</button>
    </div>
  </>
}

export default FeudInput;
