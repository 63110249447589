import React from 'react'
import { motion } from "framer-motion"

const CircleZoomBg = () => (
  <div className="absolute flex justify-center items-center w-full h-full">
    <div className="animate-circle rounded-full bg-gold w-[50vw] h-[50vw] p-0">
    </div>
  </div>
)

export default CircleZoomBg;
