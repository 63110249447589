import React, { useState, useCallback, useEffect, useMemo, useRef } from "react";
import { motion } from "framer-motion"
import Keyboard from './Keyboard';
import NextButton from './NextButton';
import MadlibsAnimatedInputs from "./MadlibsAnimatedInputs";
import Helmet from 'react-helmet';
import { getThemeColor } from '../utils/colors';
import 'react-simple-keyboard/build/css/index.css';
import '../styles/form.css'
import '../styles/layout.css'

const templateRegex = /\{([0-9,a-z,-]+)\}/g;

const MadlibsForm = ({ template, onFinished }) => {

  const [index, setIndex] = useState(0);
  const [entries, setEntries] = useState([]);
  const [exiting, setExiting] = useState(false);
  const matches = [...template.matchAll(templateRegex)];

  const next = () => {
    if (index < matches.length - 1) {
      setIndex(index + 1);
      setEntries([...entries]);
    } else {
      finished();
    }
  }

  useEffect(() => {
    keyboard.current.setInput(entries[index])
  }, [index, keyboard])

  const keyboard = useRef();

  const onChange = useCallback((input) => {
    const newEntries = [...entries];
    newEntries[index] = input;
    setEntries(newEntries);
  }, [index])

  const onKeyPress = (key) => {
    if (key == "{enter}") next();
    if (key == "{bksp}" && !entries[index]) {
      const newIndex = Math.max(index - 1, 0)
      setIndex(newIndex);
    }
  }

  const finished = useCallback(() => {

    const templateArray = template.split(" ");
    const textArray = [];

    let entry = 0
    for (var i = 0; i < templateArray.length; i++) {
      const word = templateArray[i];
      if (word.match(templateRegex)) {
        let replacement = entries[entry];
        if (entry && word.indexOf("capitalize") > -1) {
          replacement = replacement && replacement.charAt(0).toUpperCase() + replacement.slice(1);
        }
        if (replacement && word.indexOf("ing") > -1) {
          replacement = replacement + "ing";
        }
        if (replacement && word.indexOf(".") > -1) {
          replacement = replacement + ".";
        }
        if (replacement && word.indexOf(",") > -1) {
          replacement = replacement + ",";
        }
        textArray.push(<React.Fragment key={i}>
          <span className="px-3 bg-brown text-red text-sans font-bold pb-1 rounded-full">
            {replacement}
          </span>&nbsp;
        </React.Fragment>);
        entry++;
      } else {
        ;
        textArray.push(<React.Fragment key={i}>{word}&nbsp;</React.Fragment>);
      }
      if (word.indexOf(".") > -1) {
        textArray.push(<React.Fragment key={i + "."}></React.Fragment>);
      }
    }

    setExiting(true);

    window.setTimeout(() => {
      onFinished(textArray)
    }, 1500);

    //onFinished(template);
  }, [entries, template]);

  const bgColor = getThemeColor("red");

  const handleInputClick = (index) => {
    setIndex(index);
    keyboard.current.setInput(entries[index]);
  }

  return <div className="bg-red text-white p-2 h-full flex flex-col justify-stretch">
    <Helmet>
      <meta name="theme-color" content={bgColor} />
    </Helmet>
    <div className="widget-area">
      <div className="flex flex-col justify-stretch h-full">
        <MadlibsAnimatedInputs matches={matches} entries={entries} active={index} exiting={exiting} onClick={handleInputClick} />
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: exiting ? 0 : 1 }}
          transition={{ duration: .5, delay: (exiting ? 1 : 2) }}>
          <Keyboard
            onChange={onChange}
            onKeyPress={onKeyPress}
            ref={keyboard}
          />
        </motion.div>
      </div>
    </div>
    <div className="button-area">
      {!exiting ?
        <NextButton text="next" onClick={next} delay={1} />
        : null}
    </div>
  </div>
}

export default MadlibsForm;
