import React from "react";
import NextButton from "./NextButton";
import { motion } from "framer-motion"
import { getThemeColor } from '../utils/colors';
import { withPrefix } from 'gatsby';
import Lottie from "./Lottie";

const CelebrityDinnerResults = ({results, onNext}) => {

  const bannerContainer = {
    show: {
      transition: {
        staggerChildren: 0.25
      }
    }
  }

  const bannerItem = {
    hidden: { y: "-100%" },
    show: {
      y: 0,
      transition: {
        ease: "easeOut",
        duration: .9
      }
    }
  }

  const textContainer = {
    show: {
      transition: {
        staggerChildren: 1
      }
    }
  }

  const textItem = {
    hidden: { x: "-100%" },
    show: {
      x: 0,
      transition: {
        ease: "easeOut",
        duration: .9
      }
    }
  }

  const resultsDropNodes = [];
  const resultsTextNodes = [];
  for(var i=0; i < 3; i++){
    const result = results[i];
    const bgColor = getThemeColor(result.bgColor, "blue");

    resultsDropNodes.push(<motion.div variants={bannerItem} className={`w-1/3 rounded-b-full celeb-result-text--end`} style={{"background":bgColor}}>
        {result.svg ?
          <img src={withPrefix(`/celebrities/${result.svg}`)} className="h-full w-full"/>
        :
          <div className="h-full w-full" style={{marginBottom:"-3em"}}><Lottie src={result.lottie} loop={true} hideButton={true} /></div>
        }
      </motion.div>);
    resultsTextNodes.push(<motion.div variants={textItem} className={`p-1 text-4xl text-bold celeb-result-text`} style={{"color":bgColor}}>
      {`${i==2 ? "and " : ""}${result.firstName} will bring the ${result.item}${i==2 ? "." : ","}`}
    </motion.div>)
  }

  return <div className={`h-full w-full bg-gold`}>
    <div className={`widget-area`}>
      <motion.div
        variants={bannerContainer}
        initial="hidden"
        animate="show"
        className="flex justify-center h-2/5">
        {resultsDropNodes}
      </motion.div>
      <motion.div
        variants={textContainer}
        initial="hidden"
        animate="show"
        className="p-4">
        {resultsTextNodes}
      </motion.div>
      <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{ease:"easeOut", delay:3, duration:1}} className="p-1 px-5 text-brown" style={{fontSize:"1.5em"}}>
        Now thats a dinner party.
      </motion.div>
    </div>
    <div className="button-area">
      <NextButton text="next" onClick={onNext} />
    </div>
  </div>
}

export default CelebrityDinnerResults;
