import React from "react";
import { motion } from "framer-motion"
import { withPrefix } from "gatsby";
import { getThemeColor } from '../utils/colors';
import Lottie from "./Lottie"

const CelebrityTile = ({celebrity, onSelected, selected}) => {

  const bgColor = getThemeColor(celebrity.bgColor, "blue");

  return <div className={`h-full w-1/3 flex flex-col relative overflow-visible`} style={{"background":bgColor}} onClick={()=>onSelected(celebrity.id)}>
    {celebrity.svg ?
      <img src={withPrefix(`/celebrities/${celebrity.svg}`)} className="h-full w-full"/>
    :
      <div className="h-full w-full pb-5" style={{marginBottom:"-3em"}}><Lottie src={celebrity.lottie} loop={true} hideButton={true} /></div>
    }
    <div className="text-gold font-medium text-center px-2 relative">{celebrity.firstName}</div>
    <div className="text-gold font-medium text-center px-2 pb-3 relative">{celebrity.lastName}</div>
    {selected ?
      <motion.div
        initial={{opacity:0}}
        animate={{opacity:1}}
        className="border-white border-4 absolute "
        style={{inset:"-3px", zIndex:"99", borderWidth: "8px"}}>
      </motion.div>
    : null}
  </div>
}

export default CelebrityTile;
