import React, { useState } from 'react';
import { motion } from "framer-motion"
import Keyboard from './Keyboard';
import NextButton from './NextButton';
import '../styles/layout.css'


const EntryForm = ({ labelText, onFinished }) => {

  const [name, setName] = useState("");
  const [exiting, setExiting] = useState(false);

  const onChange = (input) => {
    setName(input)
  }

  const exit = () => {
    typeof window != 'undefined' && window.localStorage.setItem("sspp-name", name);

    try {
      typeof window !== "undefined" && window.gtag("event", "name_entered", {
        index: name
      });
    } catch (e) {
      console.log(e);
    }

    setExiting(true);
    window.setTimeout(() => {
      onFinished();
    }, 1500);
  }

  const onKeyPress = (key) => {
    if (key == "{enter}") exit();
  }

  const inputY = exiting ? "90vh" : 0;
  const letsGoOpacity = exiting ? 1 : 0;
  const disapearingCompontentsOpacity = exiting ? 0 : 1;

  return <div className="bg-red text-white p-2 h-full flex flex-col justify-stretch">
    <div className="widget-area">
      <div className="flex flex-col justify-evenly h-full">
        <div style={{ opacity: disapearingCompontentsOpacity }}>
          <motion.div
            initial={{ y: -200 }}
            animate={{ y: 0 }}
            transition={{ ease: "easeOut", duration: .9 }}
            className="color-white p-4 text-4xl entry-form-label text-helvetica-neue">
            <label>{labelText}</label>
          </motion.div>
        </div>
        <div className="relative mx-4 mb-2">
          <motion.div
            animate={{ opacity: letsGoOpacity }}
            transition={{ ease: "easeOut", duration: .5 }}
            className="absolute w-full text-6xl top-0 ">
            <span className="text-gold">Let's do this</span>
            <span className="text-white capitalize">&nbsp;{name}</span>
            <span className="text-gold">!</span>
          </motion.div>
          <motion.div
            initial={{ scaleY: 4, scaleX: 1.5, y: 0 }}
            animate={{ scaleY: 1, scaleX: 1, y: inputY }}
            transition={{ y: { ease: "easeIn", duration: .75 }, default: { ease: "easeOut", duration: .5 } }}
            className="bg-gold absolute h-full w-full">
          </motion.div>
          <div style={{ opacity: disapearingCompontentsOpacity }} className="text-6xl p-4 max-h-3 overflow-hidden capitalize min-h-[10rem] relative cursor cursor--brown entry-form-input">
            {name}
          </div>
        </div>
        <div style={{ opacity: disapearingCompontentsOpacity }}>
          <motion.div
            initial={{ scale: .7, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: .5 }}>
            <Keyboard
              onChange={onChange}
              onKeyPress={onKeyPress}
            />
          </motion.div>
        </div>
      </div>
    </div>
    <div className="button-area">
      <NextButton text="next" onClick={exit} />
    </div>
  </div>
}

export default EntryForm;
