import React, { useState, useCallback, useEffect, useRef } from "react";

import Lottie from "./Lottie";
import EntryForm from "./EntryForm";
import Mp4 from "./Mp4";
import MadlibsContainer from "./MadlibsContainer";
import CelebrityDinnerContainer from "./CelebrityDinnerContainer";
import FeudContainer from "./FeudContainer";
import ChooseOne from "./ChooseOne";
import FlowComponent from "./FlowComponent";
import WouldYouRatherContainer from "./WouldYouRatherContainer";
import PictureThisContainer from "./PictureThisContainer";
import GameIntro from "./GameIntro";
import FinalForm from "./FinalForm";
import { getJson } from "../utils/cache";
import { withPrefix } from "gatsby";
import moment from "moment";

const ActivityFlow = ({ flow, onFinished, initalIndex }) => {

  initalIndex = parseInt(initalIndex);
  const [index, setIndex] = useState(!isNaN(initalIndex) ? initalIndex : 0);

  const activity = flow[index];
  const sessionTime = useRef();
  const activityTime = useRef();

  useEffect(() => {
    try {
      typeof window !== "undefined" && window.gtag("event", "load_flow", {
        inital_index: initalIndex,
        length: flow.length
      });
    } catch (e) {
      console.log(e);
    }

    sessionTime.current = moment();

    setIndex(initalIndex || 0);
    flow.map((activity) => {
      if (activity.preload) {
        activity.preload.map((src) => {
          if (src.indexOf(".json") > -1) {
            getJson(src);
          }
        })
      }
    })
  }, [flow, initalIndex]);


  const next = useCallback(() => {

    if (index > 0) {
      try {
        typeof window !== "undefined" && window.gtag("event", "activity_finish", {
          index: index - 1,
          id: activity.id,
          type: activity.type,
          activityTime: moment().diff(activityTime.current),
          sessionTime: moment().diff(sessionTime.current)
        });
      } catch (e) {
        console.log(e);
      }
    }

    if (index + 1 >= flow.length) {
      onFinished && onFinished();
    } else {
      activityTime.current = moment();
      const nextActivity = flow[index + 1]
      try {
        if (typeof window !== "undefined") {
          window.gtag('set', 'page_path', nextActivity.id);
          window.gtag('event', 'page_view');
          window.gtag("event", "activity_start", {
            index: index - 1,
            id: nextActivity.id,
            type: nextActivity.type,
            sessionTime: moment().diff(sessionTime.current)
          });
        }
      } catch (e) {
        console.log(e);
      }

      setIndex(index + 1);
    }
  }, [index]);

  const videoSource = [];
  flow.map((activity) => {
    if (activity.preload) {
      activity.preload.map((src) => {
        if (src.indexOf(".mp4") > -1) {
          if (videoSource.indexOf(src) == -1) videoSource.push(src);
        } else if (src.indexOf(".jpg") > -1 || src.indexOf(".png") > -1) {
          var img = new Image();
          img.src = withPrefix(src);
        }
      })
    }
  })

  if (activity) {
    return <>{
      {
        "entryForm": <EntryForm {...activity} onFinished={next} />,
        "chooseOne": <ChooseOne {...activity} onFinished={next} />,
        "lottie": <Lottie {...activity} onFinished={next} />,
        "madlibs": <MadlibsContainer {...activity} onFinished={next} />,
        "mp4": <Mp4 {...activity} onFinished={next} />,
        "celebrities": <CelebrityDinnerContainer {...activity} onFinished={next} />,
        "feud": <FeudContainer {...activity} onFinished={next} />,
        "flow": <FlowComponent {...activity} onFinished={next} />,
        "wouldYouRather": <WouldYouRatherContainer {...activity} onFinished={next} />,
        "gameIntro": <GameIntro {...activity} onFinished={next} />,
        "pictureThis": <PictureThisContainer {...activity} onFinished={next} />,
        "finalForm": <FinalForm {...activity} />
      }[activity.type]
    }
      <div className="videoPreload">
        {videoSource.map((src) => <video key={src} preload="auto" src={withPrefix(src)} />)}
      </div>
    </>
  } else {
    console.log("Missing activity");
    return <></>
  }
}

export default ActivityFlow;
