import React, {useState} from 'react';
import GameIntro from "./GameIntro";
import MadlibsForm from "./MadlibsForm";
import MadlibsResult from "./MadlibsResult";
import { motion } from "framer-motion"

import "../styles/madlibs.css";


const MadlibsContainer = ({introText, introBg, introColor, madlibsTemplate, onFinished}) => {

  const [results, setResults] = useState([])
  const [gamePhase, setGamePhase] = useState(0)

  const handleFormSubmit = (results) => {
    setResults(results);
    setGamePhase(2)
  }

  return <>
    {[
      <GameIntro text={introText} bg={introBg} color={introColor} onFinished={()=>setGamePhase(1)}/>,
      <MadlibsForm template={madlibsTemplate} onFinished={handleFormSubmit} />,
      <MadlibsResult results={results} onFinished={onFinished} />
    ][gamePhase]}
  </>
}

export default MadlibsContainer;
